import { Auth } from "aws-amplify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import logoAntropomediaToolsColor from "../../assets/images/logo-antropomedia-tools-color.png";
import { useAuth } from "../../utils";
import NewPasswordModal from "./NewPasswordModal";
import "./styles.css";

let user = {};
const loginData = {};
const handleChange = ({ target }) => (loginData[target.name] = target.value);

const LoginForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [warningString, setWarningString] = useState("");
  const { t } = useTranslation();
  const { authState } = useAuth();

  if (authState === "signin") {
    return <Redirect to="/home" />;
  }

  const toggleShowModal = () => setShowModal(!showModal);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = loginData;
    try {
      user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        toggleShowModal();
      }
    } catch (error) {
      console.error(t("console_messages.sign_in_error"), error);
      setWarningString(error.message);
    }
  };
  return (
    <>
      <Card
        tag={Form}
        className="login-form rounded-xl shadow-lg"
        onSubmit={handleSubmit}
      >
        <CardImg
          top
          src={logoAntropomediaToolsColor}
          alt="Antropomedia Tools Logo"
        />
        <CardBody>
          <CardTitle tag="h1" className="lead">
            {t("login.title")}
          </CardTitle>
          <FormGroup>
            <Label for="username" className="sr-only">
              {t("login.username_input_label")}
            </Label>
            <Input
              required
              id="username"
              name="username"
              type="text"
              onChange={handleChange}
              placeholder={t("login.username_input_label")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password" className="sr-only">
              {t("login.password_input_label")}
            </Label>
            <Input
              required
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              placeholder={t("login.password_input_label")}
            />
          </FormGroup>
          {warningString && <Alert color="danger">{warningString}</Alert>}
          <Button color="primary" size="lg" type="submit" block>
            {t("login.log_in_button_text")}
          </Button>
        </CardBody>
      </Card>
      <NewPasswordModal
        user={user}
        isOpen={showModal}
        toggle={toggleShowModal}
      />
    </>
  );
};

export default LoginForm;
