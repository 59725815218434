import { Amplify } from "aws-amplify";
import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import awsconfig from "./aws-exports";
import PrivateRoute from "./components/private-route";
import { TribesDataContextProvider } from "./components/tribes-data";
import Login from "./pages/login";

Amplify.configure(awsconfig);

const Home = lazy(() => import("./pages/home"));

const App = () => {
  return (
    <TribesDataContextProvider>
      <Router>
        <Suspense fallback={<>Loading...</>}>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/home" component={Home} />
            <Route path="/" render={() => <Redirect to="/login" />} />
          </Switch>
        </Suspense>
      </Router>
    </TribesDataContextProvider>
  );
};

export default App;
