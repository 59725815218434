import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const passwordData = {};
const handleChange = ({ target }) => (passwordData[target.name] = target.value);

const NewPasswordModal = ({ isOpen, toggle, user }) => {
  const [warningString, setWarningString] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWarningString("");
    const { newPassword, confirmPassword } = passwordData;
    if (newPassword !== confirmPassword) {
      setWarningString(t("login.passwords_not_match"));
      return;
    }

    try {
      await Auth.completeNewPassword(user, newPassword);
      toggle();
    } catch (error) {
      console.error(t("console_messages.new_password"), error);
      setWarningString(error.message);
    }
  };
  return (
    <Modal backdrop="static" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Cambiar contraseña</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="newPassword" className="sr-only">
              {t("login.new_password_input_label")}
            </Label>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder={t("login.new_password_input_label")}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword" className="sr-only">
              {t("login.confirm_password_input_label")}
            </Label>
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("login.confirm_password_input_label")}
              onChange={handleChange}
            />
          </FormGroup>
          {warningString && <Alert color="danger">{warningString}</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Aceptar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

NewPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
};

export default NewPasswordModal;
