const initialStore = {
  isLoading: false,
  data: null,
  error: null,
  pagination: {
    itemsLength: 0,
    currentPage: 1,
    todosPerPage: 15,
    searchTerm: "",
    target: "",
    conversor: "",
  },
  graphInteractions: {
    selectedNode: null,
    selectedNodeId: null,
    selectedColor: null,
    neighbors: [],
  },
};

export default initialStore;
