import LoginForm from "./LoginForm";
import "./styles.css";

const Login = () => (
  <main role="main" className="d-flex flex-row vh-100 no-gutters">
    <section className="flex-grow-1 flex-shrink-0 w-auto bg-light d-flex justify-content-center align-items-center">
      <LoginForm />
    </section>
    <aside className="login-cover flex-grow-0 flex-shrink-1 d-none d-md-block col-md-6 col-lg-8 col-xl-9" />
  </main>
);

export default Login;
