import { useEffect, useRef, useState } from "react";

const DESIRED_REM = 20.8;
const rem = parseInt(getComputedStyle(document.documentElement).fontSize);

const useGraphContainer = () => {
  const graphContainerRef = useRef(null);
  const graphContainer = (
    <div
      className="rounded-xl overflow-hidden shadow-lg"
      ref={graphContainerRef}
    />
  );

  const [sizeGraph, setSizeGraph] = useState({
    height: document.documentElement.clientHeight - DESIRED_REM * rem,
    width: graphContainerRef.current?.parentElement.clientWidth,
  });

  const handleResize = () =>
    setSizeGraph({
      height: document.documentElement.clientHeight - DESIRED_REM * rem,
      width: graphContainerRef.current.parentElement.clientWidth,
    });

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(handleResize, [
    graphContainerRef.current?.parentElement.clientWidth,
  ]);

  return [graphContainer, graphContainerRef, sizeGraph];
};

export default useGraphContainer;
