import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";

const useAuth = () => {
  const [authState, setAuthState] = useState("loading");
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState(null);

  const handleAuthEvent = {
    signIn: async () => {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
      setGroups(
        userData.signInUserSession.accessToken.payload["cognito:groups"]
      );
      setAuthState("signin");
    },
    signOut: () => {
      setUser(null);
      setGroups(null);
      setAuthState("signout");
    },
    signIn_failure: () => {
      setUser(null);
      setGroups(null);
      setAuthState("signout");
    },
  };
  useEffect(() => {
    handleAuthEvent.signIn().catch(handleAuthEvent.signOut);
    Hub.listen("auth", ({ payload }) => handleAuthEvent[payload.event]());

    return Hub.remove("auth", handleAuthEvent.signOut);
  }, []);

  return { authState, user, groups };
};

export default useAuth;
