import { useReducer } from "react";
import initialStore from "./initialStore";
import TribesDataContext from "./TribesDataContext";
import reducer from "./reducer";

const TribesDataContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialStore);

  return <TribesDataContext.Provider {...props} value={{ state, dispatch }} />;
};

export default TribesDataContextProvider;
