export const FETCH_TRIBES_DATA_PENDING = "FETCH_TRIBES_DATA_PENDING";
export const FETCH_TRIBES_DATA_SUCCESSFUL = "FETCH_TRIBES_DATA_SUCCESSFUL";
export const FETCH_TRIBES_DATA_ERROR = "FETCH_TRIBES_DATA_ERROR";
export const SET_ITEMS_LENGTH = "SET_ITEMS_LENGTH";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SELECTED_NODE = "SET_SELECTED_NODE";
export const CLEAR_SELECTED_NODE = "CLEAR_SELECTED_NODE";
export const SET_SELECTED_NODE_ID = "SET_SELECTED_NODE_ID";
export const CLEAR_SELECTED_NODE_ID = "CLEAR_SELECTED_NODE_ID";
export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
export const CLEAR_SELECTED_COLOR = "CLEAR_SELECTED_COLOR";
export const SET_NEIGHBORS = "SET_NEIGHBORS";
export const CLEAR_NEIGHBORS = "CLEAR_NEIGHBORS";
export const CLEAR_SELECTED_ELEMENT = "CLEAR_SELECTED_ELEMENT";
export const SET_TARGET_FILTER = "SET_TARGET_FILTER";
export const SET_CONVERSOR_FILTER = "SET_CONVERSOR_FILTER";

const REDUCER_ACTIONS = {
  [FETCH_TRIBES_DATA_PENDING]: (state) => ({ ...state, isLoading: true }),
  [FETCH_TRIBES_DATA_SUCCESSFUL]: (state, payload) => ({
    ...state,
    isLoading: false,
    data: payload,
  }),
  [FETCH_TRIBES_DATA_ERROR]: (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),
  [SET_ITEMS_LENGTH]: (state, payload) => ({
    ...state,
    pagination: {
      ...state.pagination,
      itemsLength: payload,
    },
  }),
  [SET_CURRENT_PAGE]: (state, payload) => ({
    ...state,
    pagination: {
      ...state.pagination,
      currentPage: payload,
    },
  }),
  [SET_SEARCH_TERM]: (state, payload) => ({
    ...state,
    pagination: {
      ...state.pagination,
      searchTerm: payload,
    },
  }),
  [SET_SELECTED_NODE]: (state, payload) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedNode: payload,
      selectedColor: null,
    },
  }),
  [CLEAR_SELECTED_NODE]: (state) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedNode: null,
      selectedNodeId: null,
    },
  }),
  [SET_SELECTED_NODE_ID]: (state, payload) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedNodeId: payload,
    },
  }),
  [CLEAR_SELECTED_NODE_ID]: (state) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedNodeId: null,
    },
  }),
  [SET_SELECTED_COLOR]: (state, payload) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedColor: payload,
      selectedNodeId: null,
    },
  }),
  [CLEAR_SELECTED_COLOR]: (state) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedColor: null,
    },
  }),
  [SET_NEIGHBORS]: (state, payload) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      neighbors: payload,
    },
  }),
  [CLEAR_NEIGHBORS]: (state) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      neighbors: [],
    },
  }),
  [CLEAR_SELECTED_ELEMENT]: (state) => ({
    ...state,
    graphInteractions: {
      ...state.graphInteractions,
      selectedNode: null,
      selectedColor: null,
      neighbors: [],
    },
  }),
  [SET_TARGET_FILTER]: (state, payload) => ({
    ...state,
    pagination: {
      ...state.pagination,
      target: payload,
      conversor: "",
    },
  }),
  [SET_CONVERSOR_FILTER]: (state, payload) => ({
    ...state,
    pagination: {
      ...state.pagination,
      conversor: payload,
      target: "",
    },
  }),
};

const reducer = (state, { type, payload }) =>
  type in REDUCER_ACTIONS ? REDUCER_ACTIONS[type](state, payload) : state;

export default reducer;
