import { useLocation } from "react-router";

const getStringAfterLastSlash = (str) => {
  const pos = str.lastIndexOf("/");

  return str.substring(pos + 1);
};

const getLastStringPath = () => {
  const { pathname } = useLocation();

  return getStringAfterLastSlash(pathname);
};

export { getStringAfterLastSlash, getLastStringPath };
