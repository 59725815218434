// eslint-disable-next-line no-undef
const { REACT_APP_ASSETS_SITE } = process.env;

export const getUrlMagnet = (tribe) =>
  `${REACT_APP_ASSETS_SITE}/tribes/${tribe}/magnet.png`;

export const getUrlCommonMagnet = (group) =>
  `${REACT_APP_ASSETS_SITE}/magnets/common-${group}.png`;

export const getUrlMain = (tribe) =>
  `${REACT_APP_ASSETS_SITE}/tribes/${tribe}/${tribe}.jpg`;

export const getCustomUrlMain = (tribe, group) =>
  `${REACT_APP_ASSETS_SITE}/tribes/${tribe}/${group}.jpg`;

export const getUrlSocialRepresentationIcon = (socialRepresentation) =>
  `${REACT_APP_ASSETS_SITE}/social-representations/${socialRepresentation}.png`;

export const getUrlCustomLogo = (group) =>
  `${REACT_APP_ASSETS_SITE}/logos/${group}.png`;

export const getUrlBrandLogo = (brand) =>
  `${REACT_APP_ASSETS_SITE}/brands/${brand}`;

export const TRIBEWATCH_LOGO = `${REACT_APP_ASSETS_SITE}/graph/Logo-TribeWatch.svg`;

export const ANTROPOMEDIA_LOGO = `${REACT_APP_ASSETS_SITE}/graph/Logo-Antropomedia.svg`;

export const NODE_IMAGE = `${REACT_APP_ASSETS_SITE}/graph/node.png`;
export const RAINBOW_IMAGE = `${REACT_APP_ASSETS_SITE}/graph/rainbow.png`;
export const EDGE_IMAGE = `${REACT_APP_ASSETS_SITE}/graph/edge.png`;

export const TARGET_ICON = `${REACT_APP_ASSETS_SITE}/icons/target.png`;
