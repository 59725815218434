import PropTypes from "prop-types";
import { Redirect, Route } from "react-router";
import { useAuth } from "../../utils";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return <>{t("split.loading_message")}</>;
};
const SignOut = () => <Redirect to="/login" />;

const handleEvent = {
  loading: Loading,
  signout: SignOut,
};

const PrivateRoute = ({ component, ...restProps }) => {
  const { authState } = useAuth();

  const ResultComponent =
    authState in handleEvent ? handleEvent[authState] : component;
  return (
    <Route
      {...restProps}
      render={(routeProps) => <ResultComponent {...routeProps} />}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
